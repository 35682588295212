import * as React from 'react'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Content from '../components/Content'
import { parseCategoryPage, toHTML } from '../parsers'
import EventList from '../components/EventList'
import AttachmentList from '../components/AttachmentList'
import ContentGrid from '../components/ContentGrid'

const eventsContainerStyle = css`
	margin-top: 40px;
	max-width: 100%;
`

const CategoryPage = ({ data, pageContext }) => {
	const lang = pageContext.language
	let { attachments, author, title, en_title, html, en_body, category, events, menu, slug, enSlug } = parseCategoryPage(
		{
			markdownRemark: data[lang],
			allMarkdownRemark: data.allMarkdownRemark,
		},
	)
	let linkToDifferentLanguage = enSlug
	if (lang === 'en') {
		title = en_title
		html = toHTML(en_body)
		linkToDifferentLanguage = slug
	}
	const filteredEvents = events.filter((event) => event.category === category)
	return (
		<Layout menu={menu} language={lang} linkToDifferentLanguage={linkToDifferentLanguage}>
			<SEO title={title} />
			<Content title={title} author={author} html={html} language={lang} />
			<ContentGrid>
				{attachments && attachments.length > 0 && <AttachmentList attachments={attachments} language={lang} />}
				<div css={eventsContainerStyle}>
					<EventList events={filteredEvents} language={lang} />
				</div>
			</ContentGrid>
		</Layout>
	)
}

export default CategoryPage

export const query = graphql`
	query($path: String!) {
		allMarkdownRemark(
			filter: { frontmatter: { layout: { eq: "event" } } }
			sort: { fields: [frontmatter___start_date], order: ASC }
		) {
			edges {
				...EventData
			}
		}
		pl: markdownRemark(fields: { slug: { eq: $path } }) {
			...StaticPageData
			frontmatter {
				category
			}
		}
		en: markdownRemark(fields: { enSlug: { eq: $path } }) {
			...StaticPageData
			frontmatter {
				category
			}
		}
	}
`
